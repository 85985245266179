import styles from './Phase3.module.css'
import Graphics from "./Graphics";
import Controls from './Controls';
import { useEffect, useState } from 'react';
import Nav from './Nav';
import VideoPlayer from './VideoPlayer';
import Loading from '../UI/Loading';
import Modal from '../UI/Modal';
import IconPlay from '../UI/IconPlay';
import IconSpotify from '../UI/Icons/IconSpotify';
import IconAppleMusic from '../UI/Icons/IconAppleMusic';
import Button from '../UI/Button';
import EmailForm from '../Intro/EmailForm';
import { products } from '../../utils/products';

export default function Phase3(props) {
  const [platform, setPlatform] = useState(1);
  const [xp, setXp] = useState();
  const [activeVideo, setActiveVideo] = useState();
  const [currentProduct, setCurrentProduct] = useState(products[0]);
  const [fan, setFan] = useState();
  const [audioReady, setAudioReady] = useState(false);

  const [emailFormActive, setEmailFormActive] = useState();
  const [playerService, setPlayerService] = useState();
  const [playerAuthActive, setPlayerAuthActive] = useState(false);

  const [merchMoving, setMerchMoving] = useState(false);

  

  useEffect(() => {
    document.addEventListener('patchbayUser', (e) => {
      if (!e.detail.user) {
        return console.log('no user')
      }
      setFan(e.detail.user)
    })
    document.addEventListener('audioReady', (e) => {
      setAudioReady(true);
    })
  }, [])

  const handleNavigate = (new_platform) => {
    setPlatform(new_platform)
  }

  const closeXp = () => {
    setXp(null);
    setActiveVideo(null);
  }

  const updateVideo = (video_id) => {
    setActiveVideo(video_id)
  }

  const updateProduct = (product_id) => {
    const product = products.find(x => x.id === product_id)
    setCurrentProduct(product);
  }

  const openPlayerAuth = () => {
    setPlayerAuthActive(true);
  }
  const closePlayerAuth = () => {
    setPlayerAuthActive(false);
  }

  const authenticate = () => {
    if (window.patchbay && playerService) {
      window.patchbay.authenticate('663d62c8c6b6ba600bef4da4', playerService);
    }
  }

  const openEmailForm = (e) => {
    closePlayerAuth();
    setPlayerService(e.target.dataset.id);
    setEmailFormActive(true);
  }

  const closeEmailForm = () => {
    setEmailFormActive(false);
    setPlayerService(null)
  }

  const updateMerchMoving = (value) => {
    setMerchMoving(value)
  }

  

  return (
    <div className={styles.wrapper}>
      <div className="patchbay-patch" data-id="663d62c8c6b6ba600bef4da4"></div>
      <div className="patchbay-patch" data-id="66a38c8d2a45341609dd0683"></div>
      <div className="patchbay-patch" data-id="66a38ca02a45341609dd06d1"></div>

      <Graphics 
        platform={platform} 
        xp={xp} 
        setXp={setXp} 
        phase={props.phase}
        updateVideo={updateVideo} 
        handleAssetsLoaded={props.handleAssetsLoaded} 
        loading={props.loading} 
        currentProduct={currentProduct}
        updateMerchMoving={updateMerchMoving}
      />
      <Controls 
        phase={props.phase}
        fan={fan}
        xp={xp} 
        platform={platform} 
        handleNavigate={handleNavigate} 
        closeXp={closeXp}
        currentProduct={currentProduct}
        updateProduct={updateProduct}
        merchMoving={merchMoving}
      />
      {xp === 'video' && activeVideo && <VideoPlayer video_id={activeVideo} close={closeXp} />}
      <Nav handleNavigate={handleNavigate} phase={props.phase} />

      

      {!audioReady && (
        <>
          <div onClick={openPlayerAuth} className={styles.playerAuth}>
            <IconPlay /> LISTEN
          </div>
          <Modal
            active={playerAuthActive}
            close={closePlayerAuth}
          >
            <div className={styles.playerAuthCta}>
              <h3>Choose a streaming service</h3>
              <Button handleClick={openEmailForm} id='663d62c8c6b6ba600bef4da7' role='spotify'><IconSpotify /> SPOTIFY</Button>
              <Button handleClick={openEmailForm} id='663d6385c6b6ba600bef4e99' role='apple-music'><IconAppleMusic /> APPLE MUSIC</Button>
            </div>
          </Modal>
        </>
      )}

      {emailFormActive && <EmailForm close={closeEmailForm} fan={fan} authenticate={authenticate} />}
      
      
        


      <div className="patchbay-player" data-id="663d62c8c6b6ba600bef4da4"></div>

      {props.loading && <Loading />}
    </div>
  )
}