import * as THREE from 'three';
import { useGSAP } from '@gsap/react'
import { useEffect, useRef, useState } from "react";

import styles from './Phase3.module.css';

import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import Stats from 'three/addons/libs/stats.module.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { products } from '../../utils/products';

let globe;
const debug = false;

let scene, camera, renderer, composer, animationLoop, stats;

let bloomComposer, finalComposer;
let materials, darkMaterial;
const BLOOM_SCENE = 1;
let bloomLayer;

let raycaster, pointer, tempVector, navHelper, controls;
let textureLoader, modelLoader;

let world, animations;
let camera_speed = 0.01;
let camera_dummy, camera_target_1, camera_pointer_1, camera_target_2, camera_pointer_2, camera_target_3, camera_pointer_3, camera_target_4, camera_pointer_4, camera_target_5, camera_pointer_5;

let lightRing1, lightRing2;
let album, razr;
let merchView = true;
let activeOject;

let objects = [];
let product_carousel;

let gsap;

export default function Graphics(props) {
  const webglWrapper = useRef();
  const imFreeLyricRef = useRef();
  const imFreeVisualizerRef = useRef();
  const imFreeOfficialRef = useRef();
  const chasinRef = useRef();

  const merchLink = useRef();
  const albumLink = useRef();
  const singleImfreeLink = useRef();
  const singleChasin = useRef();
  
  const [previousProduct, setPreviousProduct] = useState(1);
  

  useEffect(() => {
    initGraphics();
  }, [])

  useGSAP(() => {
    gsap = window.gsap;

    if (!props.loading) {
      setTimeout(() => {
        gsap.to(album.position, { 
          duration: 2, 
          ease: "power2.inOut",
          y: 3
        })

        setTimeout(() => {
          album.userData.can_rotate = true;
        }, 2000);
      }, 1500);
      
      if (product_carousel && props.currentProduct.id !== previousProduct) {
        props.updateMerchMoving(true);

        const product_count = 8;
        const rotation_increment = 360 / product_count;
        const rotation_increment_radians = rotation_increment * (Math.PI / 180);
        
        let rotation;
        if (previousProduct === 1 && props.currentProduct.id === product_count) {
          rotation = rotation_increment_radians;
        } else if (previousProduct === product_count && props.currentProduct.id === 1) {
          rotation = -rotation_increment_radians;
        } else if (props.currentProduct.id < previousProduct) {
          rotation = rotation_increment_radians;
        } else if (props.currentProduct.id > previousProduct) {
          rotation = -rotation_increment_radians;
        }

        gsap.to(product_carousel.rotation, { 
          duration: 1, 
          ease: "power2.inOut",
          y: product_carousel.rotation.y + rotation,
          onComplete: () => {
            props.updateMerchMoving(false);
          }
        })

        setPreviousProduct(props.currentProduct.id);
      }
    }
  }, { dependencies: [props.loading, props.currentProduct], scope: webglWrapper })

  useEffect(() => {
    if (camera_target_1) {
      if (props.platform === 1) {
        camera.userData.targetPos.copy(camera_target_1.position);
        camera_dummy.userData.targetPos.copy(camera_pointer_1.position);
        merchView = true;
        activeOject = album;
      } else if (props.platform === 2) {
        camera_speed = 0.04;
        camera.userData.targetPos.copy(camera_target_2.position);
        camera_dummy.userData.targetPos.copy(camera_pointer_2.position);
        merchView = false;
        activeOject = null;
      } else if (props.platform === 3) {
        camera_speed = 0.04;
        camera.userData.targetPos.copy(camera_target_3.position);
        camera_dummy.userData.targetPos.copy(camera_pointer_3.position);
        merchView = false;
        activeOject = null;
      } else if (props.platform === 4) {
        camera_speed = 0.04;
        camera.userData.targetPos.copy(camera_target_4.position);
        camera_dummy.userData.targetPos.copy(camera_pointer_4.position);
        merchView = false;
        activeOject = null;
      } else if (props.platform === 5) {
        camera_speed = 0.04;
        camera.userData.targetPos.copy(camera_target_5.position);
        camera_dummy.userData.targetPos.copy(camera_pointer_5.position);
        merchView = false;
        activeOject = null;
      }
    }
  }, [props.platform])

  useEffect(() => {
    if (props.platform === 3 && !props.xp) {
      razr.targetPosition.copy(razr.startingPosition);
      setTimeout(() => {
        merchView = false;
      }, 2000);
    }
  }, [props.xp, props.platform])

  const initGraphics = () => {
    scene = new THREE.Scene();

    camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.1, 1000 );
    camera.userData.targetPos = new THREE.Vector3(0,0,0)

    camera.position.z = 5;
    
    const cameraDummyGeo = new THREE.BoxGeometry(0.5, 0.5, 0.5)
    const cameraDummyMat = new THREE.MeshBasicMaterial({ wireframe: true, color: 0xFF0000 })
    camera_dummy = new THREE.Mesh(cameraDummyGeo, cameraDummyMat)
    camera_dummy.userData.targetPos = new THREE.Vector3()
    camera_dummy.visible = false;
    scene.add(camera_dummy);

    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize( window.innerWidth, window.innerHeight );

    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.shadowMap.enabled = true;
    webglWrapper.current.appendChild( renderer.domElement );

    

    window.addEventListener('resize', resize, false);

    const light = new THREE.AmbientLight( 0xFFFFDC, 1 ); // soft white light
    scene.add( light );

    const directionalLight = new THREE.DirectionalLight( 0xfef7d4, 4 );
    directionalLight.position.set( 1, 8 , 1 ).normalize();
    scene .add( directionalLight );

    directionalLight.position.set(20, 60, 10);
    directionalLight.target.position.set(0, 0, 0);
    directionalLight.castShadow = true;
    directionalLight.shadow.bias = -0.005;
    directionalLight.shadow.mapSize.x = 1000;
    directionalLight.shadow.mapSize.y = 1000;
    directionalLight.shadow.camera.top = 40;
    directionalLight.shadow.camera.bottom = -40;
    directionalLight.shadow.camera.left = -40;
    directionalLight.shadow.camera.right = 40;
    directionalLight.shadow.camera.near = 1;
    directionalLight.shadow.camera.far = 80;
    scene.add(directionalLight);
    scene.add(directionalLight.target);




    if (debug) {
      const helper = new THREE.DirectionalLightHelper( directionalLight, 5 );
      scene.add( helper );  
      scene.add( new THREE.CameraHelper( directionalLight.shadow.camera ) );

      stats = new Stats();
      stats.domElement.style.cssText = 'position:absolute;top:0px;right:0px;';
      document.body.appendChild( stats.dom );
      controls = new OrbitControls( camera, renderer.domElement );
    }


    // INTERACTION
    raycaster = new THREE.Raycaster();
		pointer = new THREE.Vector2();
    tempVector = new THREE.Vector3();

    // MODELS
    textureLoader = new THREE.TextureLoader()
    modelLoader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/'); 
    modelLoader.setDRACOLoader( dracoLoader );

    const envMapLoader = new THREE.PMREMGenerator(renderer)
    // new RGBELoader().load('./textures/environment.hdr', hdrmap => {
      // const envMap = envMapLoader.fromEquirectangular(hdrmap).texture

      // scene.environment = envMap;
      // renderer.toneMapping = THREE.ACESFilmicToneMapping;
      // renderer.toneMappingExposure = 0.2;
      // // renderer.toneMapping = THREE.ReinhardToneMapping;

      // hdrmap.dispose()
      // envMapLoader.dispose()



      bloomLayer = new THREE.Layers();
			bloomLayer.set( BLOOM_SCENE );

      darkMaterial = new THREE.MeshBasicMaterial( { color: 'black' } );
			materials = {};

      
      let url;
      if (props.phase === 5) {
        url = '/models/paris-hilton-infinite-icon.glb'
      } else {
        url = 'https://ddy1csms58ecs.cloudfront.net/models/paris-hilton-infinite-icon_v6.glb';
      }
      
      modelLoader.load(url, gltf => {
        world = gltf.scene;
        animations = gltf.animations;

        world.traverse(node => {
          if (node.name.includes('album')) {
            objects.push(node)
          }

          if (node.name.includes('merch')) {
            objects.push(node)
          }

          if (node.isMesh) {
            if (!node.name.includes('_video') && node.name !== 'sky') {
              node.castShadow = true;
              node.receiveShadow = true;
            }

            if (node.name !== 'sky' && !node.name.includes('merch')) {
              node.layers.enable( BLOOM_SCENE );

              if (node.name.includes("merch_fanny-pack")) {
                console.log(node.children)
                node.children.forEach(child => {
                  child.layers.enable( BLOOM_SCENE );
                })
              }
            }
          }
        })

        const camera_target = world.getObjectByName('camera-target-0');

        camera_target_1 = world.getObjectByName('camera-target-1');
        camera_pointer_1 = world.getObjectByName('camera-pointer-1');
        camera_target_2 = world.getObjectByName('camera-target-2');
        camera_pointer_2 = world.getObjectByName('camera-pointer-2');
        camera_target_3 = world.getObjectByName('camera-target-3');
        camera_pointer_3 = world.getObjectByName('camera-pointer-3');
        camera_target_4 = world.getObjectByName('camera-target-4');
        camera_pointer_4 = world.getObjectByName('camera-pointer-4');
        camera_target_5 = world.getObjectByName('camera-target-5');
        camera_pointer_5 = world.getObjectByName('camera-pointer-5');

        camera.userData.targetPos.copy(camera_target.position);
        camera.position.copy(camera_target.position);
        camera_dummy.userData.targetPos.copy(camera_pointer_1.position);
        camera_dummy.position.copy(camera_pointer_1.position);

        setTimeout(() => {
          camera.userData.targetPos.copy(camera_target_1.position);
        }, 1000);


        lightRing1 = world.getObjectByName('ring-lights-1');
        lightRing2 = world.getObjectByName('ring-lights-2');

        album = world.getObjectByName('album');
        album.userData.can_rotate = false;
        activeOject = album;
        
        globe = world.getObjectByName('sky');
        globe.castShadow = false;
        globe.receiveShadow = false;
        const new_mat = new THREE.MeshBasicMaterial()
        new_mat.map = globe.material.map;
        globe.material = new_mat;

        const single_1 = world.getObjectByName('single_imfree');
        objects.push(single_1);

        const pointLight = new THREE.PointLight( 0xffffff, 1, 10 );
        pointLight.position.copy(single_1.position);
        pointLight.position.x -= 1;
        scene.add( pointLight );


        const single_2 = world.getObjectByName('single_chasin');
        objects.push(single_2);
        
        const pointLightMerch = new THREE.PointLight( 0xffffff, 5, 10 );
        pointLightMerch.position.copy(camera_target_2.position);
        pointLightMerch.position.x += 4;
        pointLightMerch.position.y += 0.5;
        scene.add( pointLightMerch );


        razr = world.getObjectByName('razr');
        const razr_glow = world.getObjectByName('razr-glow');
        objects.push(razr_glow)

        razr.startingPosition = new THREE.Vector3();
        razr.startingPosition.copy(razr.position);
        razr.startingQuaternion = new THREE.Quaternion();
        razr.startingQuaternion.copy(razr.quaternion);

        razr.targetPosition = new THREE.Vector3();
        razr.targetPosition.copy(razr.position);
        razr.targetQuaternion = new THREE.Quaternion();
        razr.targetQuaternion.copy(razr.quaternion);

        razr.zoomPosition = new THREE.Vector3(0, camera.position.y, razr.position.z);
        razr.zoomQuaternion = new THREE.Quaternion();
        razr.zoomQuaternion.copy(razr.startingQuaternion);
        objects.push(razr);


        const screen1 = world.getObjectByName('frame-1_video')
        const videoTexture = new THREE.VideoTexture( imFreeLyricRef.current );
        const videoMaterial = new THREE.MeshBasicMaterial({
          map:  videoTexture,
          side: THREE.DoubleSide
        });
        screen1.material = videoMaterial;
        screen1.userData.video_id = 'eKVtVJWqjPI';
        objects.push(screen1);
        imFreeLyricRef.current.play();

        const screen2 = world.getObjectByName('frame-2_video')
        const videoTextureVisualizer = new THREE.VideoTexture(imFreeOfficialRef.current );
        const videoMaterialVisualizer = new THREE.MeshBasicMaterial({
          map:  videoTextureVisualizer,
          side: THREE.DoubleSide
        });
        screen2.material = videoMaterialVisualizer;
        screen2.userData.video_id = 'zQEG1R8j9TY';
        objects.push(screen2);
        imFreeOfficialRef.current.play();

        const screen3 = world.getObjectByName('frame-3_video')
        const videoTextureImFree = new THREE.VideoTexture(imFreeVisualizerRef.current);
        const videoMaterialImFree = new THREE.MeshBasicMaterial({
          map:  videoTextureImFree,
          side: THREE.DoubleSide
        });
        screen3.material = videoMaterialImFree;
        screen3.userData.video_id = 'FB1WGcVCkj8';
        objects.push(screen3);
        imFreeVisualizerRef.current.play();

        const screen4 = world.getObjectByName('frame-4_video')
        const videoTextureChasin = new THREE.VideoTexture(chasinRef.current);
        const videoMaterialChasin = new THREE.MeshBasicMaterial({
          map:  videoTextureChasin,
          side: THREE.DoubleSide
        });
        screen4.material = videoMaterialChasin;
        screen4.userData.video_id = 'st6eGFmjvt0';
        objects.push(screen4);
        chasinRef.current.play()

        if (props.phase === 5) {
          const screen5 = world.getObjectByName('frame-5_video')
          const videoTextureBBA = new THREE.VideoTexture(chasinRef.current);
          const videoMaterialBBA = new THREE.MeshBasicMaterial({
            map:  videoTextureBBA,
            side: THREE.DoubleSide
          });
          screen5.material = videoMaterialBBA;
          screen5.userData.video_id = 'st6eGFmjvt0';
          objects.push(screen5);
          chasinRef.current.play()
        }

        product_carousel = world.getObjectByName('product-carousel');

        scene.add(world)

        initPostProcessing();
        initControls();
        animate();
        props.handleAssetsLoaded();
      })
    // })
  }

  const initPostProcessing = () => {
    const renderScene = new RenderPass( scene, camera );

    const params = {
      threshold: 0.6,
      strength: 0.6,
      radius: 0.9,
      exposure: 0.4
    }
    const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
    bloomPass.threshold = params.threshold;
    bloomPass.strength = params.strength;
    bloomPass.radius = params.radius;

    // composer.addPass( bloomPass );
    bloomComposer = new EffectComposer( renderer );
    bloomComposer.renderToScreen = false;
    bloomComposer.addPass( renderScene );
    bloomComposer.addPass( bloomPass );

    const mixPass = new ShaderPass(
      new THREE.ShaderMaterial( {
        uniforms: {
          baseTexture: { value: null },
          bloomTexture: { value: bloomComposer.renderTarget2.texture }
        },
        vertexShader: document.getElementById( 'vertexshader' ).textContent,
        fragmentShader: document.getElementById( 'fragmentshader' ).textContent,
        defines: {}
      } ), 'baseTexture'
    );
    mixPass.needsSwap = true;



    const outputPass = new OutputPass();

    finalComposer = new EffectComposer( renderer );
    finalComposer.addPass( renderScene );
    finalComposer.addPass( mixPass );
    finalComposer.addPass( outputPass );

    scene.traverse( disposeMaterial );
  }




  const disposeMaterial = (obj) => {
    if ( obj.material ) {
      obj.material.dispose();
    }
  }
  const darkenNonBloomed = ( obj ) => {
    if ( obj.isMesh && bloomLayer.test( obj.layers ) === false ) {
      materials[ obj.uuid ] = obj.material;
      obj.material = darkMaterial;
    }
  }
  const restoreMaterial = ( obj ) => {
    if ( materials[ obj.uuid ] ) {
      obj.material = materials[ obj.uuid ];
      delete materials[ obj.uuid ];
    }
  }

  const animate = () => {
    requestAnimationFrame(animate);

    if (!debug) {
      camera_dummy.position.lerp(camera_dummy.userData.targetPos, camera_speed);
      camera.position.lerp(camera.userData.targetPos, camera_speed);
      camera.lookAt(camera_dummy.position);
    }

    if (lightRing1 && lightRing2) {
      lightRing1.rotation.y += 0.001;
      lightRing2.rotation.y -= 0.001;
    }

    if (album && album.userData.can_rotate) {
      album.rotation.y += 0.01;
    }

    if (razr && !merchView) {
      razr.rotation.y += 0.01;
    } else if (razr && merchView) {
      razr.position.lerp(razr.targetPosition, 0.09);
      razr.quaternion.slerp(razr.targetQuaternion, 0.09);
    }

    // composer.render();


    scene.traverse( darkenNonBloomed );
    bloomComposer.render();
    scene.traverse( restoreMaterial );
    // render the entire scene, then render bloom scene on top
    finalComposer.render();
  }

  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }




  const initControls = () => {
    webglWrapper.current.addEventListener( 'pointermove', onPointerMove );
    webglWrapper.current.addEventListener( 'mousedown', setPointerStart );
    webglWrapper.current.addEventListener( 'mouseup', handleClick );
    webglWrapper.current.addEventListener( 'touchstart', setPointerStart );
    webglWrapper.current.addEventListener( 'touchend', handleClick );
  }

  let pointerStart, pointerEnd;
  const setPointerStart = (e) => {
    pointerStart = e.touches ? e.touches[0] : e.clientX;
    pointerEnd = pointerStart;
    setTargetLocation(e);
    merchPointerStart(e);
  }

  const onPointerMove = (e) => {
    pointerEnd = e.touches ? e.touches[0] : e.clientX;
    setTargetLocation(e)
    merchPointerMove(e);
  }

  const setTargetLocation = (e) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
    pointer.x = ( clientX / renderer.domElement.clientWidth ) * 2 - 1;
    pointer.y = - ( clientY / renderer.domElement.clientHeight ) * 2 + 1;

    raycaster.setFromCamera( pointer, camera );
    
    if (world) {
      const intersectsObjects = raycaster.intersectObjects(objects);
      
      if (intersectsObjects.length > 0) {
        pointer.target = intersectsObjects[ 0 ];
      } else {
        pointer.target = null;
      }
    }
  }

  const handleClick = (e) => {
    const singleClick = pointerStart === pointerEnd;
    
    if (singleClick && pointer.target && pointer.target.object) {
      const object = pointer.target.object;
      if (object.name.includes('_video')) {
        props.updateVideo(object.userData.video_id);
        props.setXp('video');
      } else if (object.name === 'razr' || object.name === 'razr-glow') {
        razr.zoomPosition.copy(camera.position);
        razr.zoomPosition.z += 1;
        razr.targetPosition.copy(razr.zoomPosition);
        razr.targetQuaternion.copy(razr.zoomQuaternion);
        merchView = true;
        props.setXp('phone');
      } else if (object.name.includes('album')) {
        albumLink.current.click();
      } else if (object.name.includes('single_imfree')) {
        singleImfreeLink.current.click();
      } else if (object.name.includes('single_chasin')) {
        singleChasin.current.click();
      } else if (object.name.includes('merch')) {
        if (object.parent) {
          const name = object.parent.name;
          const product = products.find(x => x.mesh_id === name);
          if (product) {
            merchLink.current.href = product.url;
            merchLink.current.click();
          }

        }
      }
    }
    merchPointerEnd(e);
  }

  // Merch rotation
  let mouseDown = false,
      mouseX = 0,
      mouseY = 0;
  const merchPointerMove = (e) => {
    if (merchView) {
      if (!mouseDown) {
        return;
      }
      e.preventDefault();
      var deltaX = e.clientX - mouseX,
          deltaY = e.clientY - mouseY;
      mouseX = e.clientX;
      mouseY = e.clientY;
      rotateItem(deltaX, deltaY);
    }
  }

  const merchPointerStart = (e) => {
    if (merchView) {
      e.preventDefault();
      mouseDown = true;
      mouseX = e.clientX;
      mouseY = e.clientY;
    }
  }
  const merchPointerEnd = (e) => {
    if (merchView) {
      e.preventDefault();
      mouseDown = false;
    }
  }

  const rotateItem = (deltaX, deltaY) => {
    if (merchView) {
      if (!isNaN(deltaX) && !isNaN(deltaY)) {
        activeOject.rotation.y += deltaX / 100;
      }
    }
  }

  

  return (
    <>
      <a ref={albumLink} className={styles.linkHidden} href='https://www.amazon.com/dp/B0D9MD6D9D' target='_blank'></a>
      <a ref={singleImfreeLink} className={styles.linkHidden} href='https://open.spotify.com/track/5YXlSRFFpJCiEqfn9kctWI' target='_blank'></a>
      <a ref={singleChasin} className={styles.linkHidden} href='https://open.spotify.com/album/5GzcpXxpQsBKJGPX2oDvvj' target='_blank'></a>
      <a ref={merchLink} className={styles.linkHidden} href='' target='_blank'></a>
      
      <video ref={imFreeLyricRef} crossOrigin="anonymous" playsInline muted loop>
        <source src="https://ddy1csms58ecs.cloudfront.net/videos/imfree-lyric-video_clip-1_v2.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
      <video ref={imFreeVisualizerRef} crossOrigin="anonymous" playsInline muted loop>
        <source src="https://ddy1csms58ecs.cloudfront.net/videos/imfree-visualizer-video_clip-1.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
      <video ref={imFreeOfficialRef} crossOrigin="anonymous" playsInline muted loop>
        <source src="https://ddy1csms58ecs.cloudfront.net/videos/imfree-official-video_clip-1_v2.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
      <video ref={chasinRef} crossOrigin="anonymous" playsInline muted loop>
        <source src="https://ddy1csms58ecs.cloudfront.net/videos/chasin-lyric-video_clip-1.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
      
      <div ref={webglWrapper} className={styles.webGlWrapper}></div>
    </>
  )
}